import { jwtDecode } from 'jwt-decode';

const decodeInstance = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // Sanitize URL parameters to remove 'amp;'
  urlParams.forEach((value, key) => {
    if (key.includes('amp;')) {
      const sanitizedKey = key.replace('amp;', '');
      urlParams.delete(key);
      urlParams.set(sanitizedKey, value);
    }
  });

  const instanceIdParam = urlParams.get('instance_id');
  const instanceParam = urlParams.get('instance');

  let instanceId = null;

  if (instanceIdParam) {
    instanceId = instanceIdParam;
  } else if (instanceParam) {
    try {
      const decoded = jwtDecode(instanceParam);
      instanceId = decoded.instanceId;
    } catch (error) {
      console.error('Failed to decode instance JWT', error);
    }
  }

  // Store instanceId in sessionStorage
  if (instanceId) {
    sessionStorage.setItem('instanceId', instanceId);
  } else {
    instanceId = sessionStorage.getItem('instanceId');
  }

  return instanceId;
};

export default decodeInstance;
