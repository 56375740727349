import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  Progress,
  Button,
  Heading,
  useColorModeValue as mode,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import WeblangMultilingualStepper from '../../components/WeblangMultilingualStepper';
import VideoSection from '../../components/VideoSection';
import axiosInstance from '../../utils/axiosInstance';

const TranslationStep = () => {
  const { translationRequestId } = useParams();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('parsing_website');
  const [loading, setLoading] = useState(true);
  const [translatedFileUrl, setTranslatedFileUrl] = useState(null);

  useEffect(() => {
    const fetchTranslationStatus = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/translation_requests/${translationRequestId}`);
        const currentStatus = response.data.status;
        setStatus(currentStatus);
        setProgress(response.data.progress); // Set progress from the backend

        if (currentStatus === 'pending') {
          await axiosInstance.post(`/translation_requests/${translationRequestId}/pay`);
        } else if (currentStatus === 'completed') {
          setTranslatedFileUrl(response.data.translated_file_url); // Set translated file URL
        }
      } catch (error) {
        console.error('Error fetching translation status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTranslationStatus();
    const interval = setInterval(() => {
      fetchTranslationStatus();
    }, 8000);

    return () => clearInterval(interval);
  }, [translationRequestId]);

  const getStatusText = (status) => {
    switch (status) {
      case 'parsing_website':
        return 'Analyzing your website content...';
      case 'translating':
        return 'Translating the text...';
      case 'completed':
        return 'Translation completed';
      case 'failed':
        return 'Translation failed';
      default:
        return 'Processing';
    }
  };

  const handleContinueInBackground = () => {
    navigate('/weblang_multilingual');
  };

  return (
    <Container maxW="container.md" py={8}>
      <WeblangMultilingualStepper currentStep={4} />
      <Flex direction="column" minH="80vh" justify="top">
        <Center mt={5}>
          <Heading as="h1" size="lg" fontWeight="bold" textAlign="center">
            {getStatusText(status)}
          </Heading>
        </Center>
        {status === 'completed' && translatedFileUrl ? (
          <>
            <VideoSection videoUrl="/weblang_multilingual_3.mp4" mt={8} />
            <Center>
              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
                width="100%"
                textAlign="center"
                bg={mode('gray.50', 'gray.700')}
              >
                <Heading fontSize="lg">Your translated file is ready!</Heading>
                <Button
                  as="a"
                  href={translatedFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  colorScheme="teal"
                  mt={4}
                >
                  Download Translated File
                </Button>
              </Box>
            </Center>

            <Text mt={8} mb={4}>
              <strong>Instructions to Import Translations:</strong>
            </Text>
            <Text mb={4}>
              1. Click on the 3 dots next to the added language in the Wix Multilingual dashboard.
            </Text>
            <Text mb={4}>
              2. Select <strong>Import Translations</strong>.
            </Text>
            <Text mb={4}>
              3. Upload the downloaded CSV file.
            </Text>
            <Stack direction="row" mt={6} justify="start">
              <Button colorScheme="teal" onClick={handleContinueInBackground}>
                Finish
              </Button>
            </Stack>
          </>
        ) : status === 'failed' ? (
          <>
            <Center mt={8}>
              <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
                width="100%"
                textAlign="center"
                bg={mode('red.50', 'red.700')}
              >
                <Heading fontSize="lg">We were not able to process your file.</Heading>
                <Text mt={4}>Please contact andrew@rubyroid.tech for support or re-run the failed translation on the next page.</Text>
              </Box>
            </Center>
            <Stack direction="row" mt={6} justify="start">
              <Button colorScheme="teal" onClick={handleContinueInBackground}>
                Finish
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Box mt={8} mb={4} position="relative">
              <Progress
                value={progress}
                size="lg"
                colorScheme="teal"
                sx={{
                  '& > div': {
                    transition: 'width 1s ease-in-out',
                  },
                }}
              />
              <Text
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                fontSize="sm"
                fontWeight="bold"
              >
                {`${progress}%`}
              </Text>
            </Box>
            <Center>
              <Text color={mode('gray.600', 'gray.400')}>{`Estimated time 2 minutes`}</Text>
            </Center>
            <Center mt={8}>
              <Button variant="link" onClick={handleContinueInBackground}>
                Continue in background
              </Button>
            </Center>
          </>
        )}
      </Flex>
    </Container>
  );
};

export default TranslationStep;
