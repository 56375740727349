import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Container,
  Flex,
  IconButton,
  Spinner,
  Center,
  SimpleGrid,
  Heading,
  useColorModeValue as mode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  keyframes,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import WeblangMultilingualStepper from '../../components/WeblangMultilingualStepper';
import NavigationButtons from '../../components/NavigationButtons';
import axiosInstance from '../../utils/axiosInstance';
import { PricingCard } from '../../components/PricingCard';

const PaymentStep = () => {
  const { translationRequestId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);
  const [cost, setCost] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(new URLSearchParams(location.search).get('autorefresh') === 'true');

  useEffect(() => {
    const fetchBalanceAndCost = async () => {
      try {
        const balanceResponse = await axiosInstance.get('/payments/balance');
        setBalance(balanceResponse.data.balance);

        const costResponse = await axiosInstance.get(`/translation_requests/${translationRequestId}`);
        setCost(costResponse.data.word_count);

        if (balanceResponse.data.balance < costResponse.data.word_count) {
          const plansResponse = await axiosInstance.get(`/payments/new?translation_request_id=${translationRequestId}`);
          setPlans(plansResponse.data.packages);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalanceAndCost();

    const interval = setInterval(() => {
      if (autoRefresh) {
        fetchBalance();
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [translationRequestId, autoRefresh]);

  const fetchBalance = async () => {
    try {
      setBalance(null);
      const balanceResponse = await axiosInstance.get('/payments/balance');
      setBalance(balanceResponse.data.balance);
    } catch (error) {
      console.error('Error refreshing balance:', error);
    }
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await fetchBalance();
    } catch (error) {
      console.error('Error refreshing balance:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleNext = () => {
    navigate(`/weblang_multilingual/translate/${translationRequestId}`);
  };

  const handleBack = () => {
    navigate('/weblang_multilingual/upload-csv');
  };

  const amountLacking = cost !== null && balance !== null ? cost - balance : 0;

  useEffect(() => {
    if (balance !== null && cost !== null && balance >= cost) {
      setAutoRefresh(false);
    }
  }, [balance, cost]);

  return (
    <Container maxW="container.lg" py={8}>
      <WeblangMultilingualStepper currentStep={3} />
      <Flex justify="space-between" align="center" mb={8}>
        <Box p={2} bg="gray.100" borderRadius="md" display="flex" alignItems="center" width="auto" borderRadius='full'>
          <Text mx={2} fontSize="lg">
            Translation Cost: <strong>{cost !== null ? cost : <Spinner size="sm" />} </strong> tokens
          </Text>
        </Box>
        <Flex align="center">
          <Box p={2} bg="gray.100" borderRadius="full" display="flex" alignItems="center" width="auto">
            <Text mx={2} fontSize="lg">
              Balance: <strong>{balance !== null ? balance : <Spinner size="sm" />} </strong> tokens
            </Text>
          </Box>
          <IconButton
            icon={<RepeatIcon />}
            onClick={handleRefresh}
            aria-label="Refresh balance"
            bg="none"
            borderRadius="full"
            ml={2}
            isLoading={isRefreshing}
          />
        </Flex>
      </Flex>
      {autoRefresh && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Balance Update in Progress</AlertTitle>
            <AlertDescription>
              Balance top-up can take up to 2 minutes. If it wasn't updated reach out for support and andrew@rubyroid.tech.
            </AlertDescription>
          </Box>
        </Alert>
      )}
      <>
        {balance !== null && cost !== null && balance >= cost ? (
          <Box mt={8} mb={8} textAlign="left">
            <Heading as="h1" size="md" fontWeight="extrabold">You are all set!</Heading>
            <Text
              mt="4"
              color={mode('gray.600', 'gray.400')}
              textAlign="left"
            >
              You have sufficient tokens to complete this translation. By clicking "Next," your account will be <strong>charged {cost} tokens</strong>.
            </Text>
            <Text
              mt="4"
              fontSize="md"
              color={mode('gray.600', 'gray.400')}
              textAlign="left"
            >
              Rest assured, you won't be disappointed. Our AI will scan your website, analyze the content, and deliver the best, context-aware translation tailored to your needs.
            </Text>
          </Box>
        ) : (
          <>
            <Box mt={16} mb={8} textAlign="center">
              <Heading as="h1" size="2xl" fontWeight="extrabold">Pricing Plans</Heading>
              <Text
                mt="4"
                maxW="xl"
                mx="auto"
                fontSize="xl"
                color={mode('gray.600', 'gray.400')}
                textAlign="center"
              >
                You <strong>lack {amountLacking > 0 ? amountLacking : 0} tokens</strong> for this translation. Start building for free, then add a site plan to go live. Account plans unlock additional features.
              </Text>
            </Box>
            <SimpleGrid
              alignItems="flex-start"
              mt={{ base: '10', lg: '24' }}
              columns={{ base: 1, lg: 3 }}
              spacing={{ base: '12', lg: '8' }}
            >
              {plans.length > 0 ? (
                plans.map(plan => (
                  <PricingCard
                    key={plan.name}
                    name={plan.name}
                    description={plan.description}
                    price={plan.price}
                    features={plan.features}
                    popular={plan.popular}
                    billingType={plan.billingType}
                    url={plan.url}
                  />
                ))
              ) : (
                <Center w="full">
                  <Spinner size="xl" />
                </Center>
              )}
            </SimpleGrid>
          </>
        )}
      </>
      <NavigationButtons onBack={handleBack} onNext={handleNext} isNextDisabled={amountLacking > 0 || loading} />
    </Container>
  );
};

export default PaymentStep;
