import React from 'react';
import { HStack, Button } from '@chakra-ui/react';

const NavigationButtons = ({ onBack, onNext, isNextDisabled }) => {
  return (
    <HStack spacing={4}>
      <Button onClick={onBack} colorScheme="gray">
        Back
      </Button>
      <Button onClick={onNext} colorScheme="teal" isDisabled={isNextDisabled}>
        Next
      </Button>
    </HStack>
  );
};

export default NavigationButtons;
