import React from 'react';
import { Box, Text, Container, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import WeblangMultilingualStepper from '../../components/WeblangMultilingualStepper';
import VideoSection from '../../components/VideoSection';
import NavigationButtons from '../../components/NavigationButtons';
import { useNavigate, useLocation } from 'react-router-dom';

const InstallStep = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = () => {
    navigate('/weblang_multilingual/add-language');
  };

  return (
    <Container maxW="container.md" py={8}>
      <WeblangMultilingualStepper currentStep={0} />
      {location.state?.showAlert && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Wix Multilingual Not Set Up!</AlertTitle>
            <AlertDescription>
              It looks like Wix Multilingual isn't set up on your website. Please follow the instructions below to set it up.
            </AlertDescription>
          </Box>
        </Alert>
      )}
      <VideoSection videoUrl="/weblang_multilingual_1.mp4" />
      <Text mb={8} fontWeight="bold">
        Instructions to Set Up Wix Multilingual
      </Text>
      <Text mb={4}>
        1. <strong>Open Settings:</strong> Navigate to the <strong>Settings</strong> menu in your Wix dashboard.
      </Text>
      <Text mb={4}>
        2. <strong>Access Multilingual:</strong> Click on <strong>Multilingual</strong> under the Settings options.
      </Text>
      <Text mb={4}>
        3. <strong>Set Up Main Language:</strong> Select the main language for your site and choose an icon to represent this language.
      </Text>
      <Text mb={4}>
        4. <strong>Already Set Up?</strong> If Multilingual is already set up, you can proceed to the next step.
      </Text>
      <NavigationButtons onBack={() => navigate(-1)} onNext={handleNext} />
    </Container>
  );
};

export default InstallStep;
