import React, { useState, useEffect } from 'react';
import { Box, Text, Container, Button, Input, Select, Spinner, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure } from '@chakra-ui/react';
import WeblangMultilingualStepper from '../../components/WeblangMultilingualStepper';
import NavigationButtons from '../../components/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';

const UploadCSVStep = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState('');
  const [languages, setLanguages] = useState([]);
  const [primaryLanguage, setPrimaryLanguage] = useState('');
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchLanguages = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/languages');
        const data = response.data;

        // Find the primary language
        const primary = data.find(lang => lang.primary);
        setPrimaryLanguage(primary ? primary.name : '');

        // Filter out the primary language from the list of target languages
        const targetLanguages = data.filter(lang => !lang.primary);
        setLanguages(targetLanguages);
      } catch (error) {
        console.error('Error fetching languages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleNext = async () => {
    if (file && language) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('target_language', language);

      onOpen(); // Open the loading modal

      try {
        const response = await axiosInstance.post('/translation_requests', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        navigate(`/weblang_multilingual/payment/${response.data.id}`);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        onClose(); // Close the loading modal
      }
    }
  };

  const handleBack = () => {
    navigate('/weblang_multilingual/add-language');
  };

  const openFileDialog = (e) => {
    e.stopPropagation();
    document.getElementById('fileInput').click();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      setFile(files[0]);
      event.dataTransfer.clearData();
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Container maxW="container.md" py={8}>
      <WeblangMultilingualStepper currentStep={2} />
      <Box
        border="2px dashed"
        borderColor="gray.300"
        borderRadius="md"
        p={8}
        textAlign="center"
        mb={8}
        onClick={openFileDialog}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        cursor="pointer"
        _hover={{ borderColor: 'gray.400' }}
      >
        <Text mb={4}>Drag & drop your CSV file here, or</Text>
        <Button colorScheme="teal" onClick={openFileDialog}>
          Browse Files
        </Button>
        <Input
          id="fileInput"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          display="none"
        />
        {file && <Text mt={4}>{file.name}</Text>}
      </Box>
      <Box mb={8}>
        {loading ? (
          <Spinner size="md" />
        ) : (
          <>
            <Text mb={2}>Translating from <strong>{primaryLanguage}</strong> to:</Text>
            <Select placeholder="Select target language" onChange={handleLanguageChange}>
              {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </Select>
          </>
        )}
      </Box>
      <NavigationButtons onBack={handleBack} onNext={handleNext} isNextDisabled={!file || !language} />

      {/* Loading Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody display="flex" alignItems="center" justifyContent="center" p={6}>
            <Spinner size="xl" />
            <Text ml={4}>Uploading and processing your file...</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default UploadCSVStep;
