import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Container,
  Center,
  SimpleGrid,
  Heading,
  Spinner,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosInstance';
import { PricingCard } from '../../components/PricingCard';

const PricingInfo = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansResponse = await axiosInstance.get('/payments');
        setPlans(plansResponse.data.packages);
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  return (
    <Container maxW="container.lg" py={8}>
      <Box textAlign="center" mb={8}>
        <Heading as="h1" size="2xl" fontWeight="extrabold">Our Pricing Plans</Heading>
        <Text
          mt="4"
          maxW="xl"
          mx="auto"
          fontSize="xl"
          color={mode('gray.600', 'gray.400')}
          textAlign="center"
        >
          Choose a plan that suits your needs. Our flexible pricing options are designed to cater to businesses of all sizes.
        </Text>
      </Box>
      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <SimpleGrid
          alignItems="flex-start"
          mt={{ base: '10', lg: '24' }}
          columns={{ base: 1, lg: 3 }}
          spacing={{ base: '12', lg: '8' }}
        >
          {plans.length > 0 ? (
            plans.map(plan => (
              <PricingCard
                key={plan.name}
                name={plan.name}
                description={plan.description}
                price={plan.price}
                features={plan.features}
                popular={plan.popular}
                billingType={plan.billingType}
                url={plan.url}
              />
            ))
          ) : (
            <Center w="full">
              <Text>No pricing plans available at the moment.</Text>
            </Center>
          )}
        </SimpleGrid>
      )}
    </Container>
  );
};

export default PricingInfo;
