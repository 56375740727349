import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Text,
  Badge,
  useColorModeValue as mode,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  keyframes,
} from '@chakra-ui/react';
import { DownloadIcon, RepeatIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Index = () => {
  const [translationRequests, setTranslationRequests] = useState([]);
  const [loadingReRun, setLoadingReRun] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const navigate = useNavigate();

  const fetchTranslationRequests = async () => {
    setIsRefreshing(true);
    try {
      const response = await axiosInstance.get('/translation_requests');
      setTranslationRequests(response.data);
      // Check if there are any pending requests
      if (response.data.some(req => req.status !== 'completed' && req.status !== 'failed')) {
        setAutoRefresh(true);
      } else {
        setAutoRefresh(false);
      }
    } catch (error) {
      console.error('Error fetching translation requests:', error);
      setErrorMessage('Error fetching translation requests. Please try again later.');
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchTranslationRequests();

    const interval = setInterval(() => {
      if (autoRefresh) {
        fetchTranslationRequests();
      }
    }, 8000); // Refresh every 1 minute

    return () => clearInterval(interval);
  }, [autoRefresh]);

  const handleNewTranslation = () => {
    navigate('/weblang_multilingual/add-language');
  };

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  const handleReRun = async (id) => {
    setLoadingReRun((prev) => [...prev, id]);
    try {
      await axiosInstance.post(`/translation_requests/${id}/rerun`);
      fetchTranslationRequests();
    } catch (error) {
      console.error('Error re-running translation request:', error);
      const apiError = error.response?.data?.error || 'Error re-running translation request. Please try again later.';
      setErrorMessage(apiError);
    } finally {
      setLoadingReRun((prev) => prev.filter((requestId) => prev !== id));
    }
  };

  const getStatusBadge = (status, progress) => {
    switch (status) {
      case 'completed':
        return <Badge colorScheme="green">Completed</Badge>;
      case 'failed':
        return <Badge colorScheme="red">Failed</Badge>;
      case 'paid':
      case 'parsing_website':
      case 'translating':
        return (
          <Stack direction="row" align="center">
            <Badge colorScheme="yellow">In Progress</Badge>
          </Stack>
        );
      default:
        return <Badge colorScheme="gray">Unknown</Badge>;
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Flex justify="space-between" align="center" mb={8}>
        <Flex align="center">
          <Heading as="h1" size="lg" mr={2}>
            Translation Requests
          </Heading>
          <IconButton
            icon={<RepeatIcon />}
            aria-label="Refresh"
            variant="outline"
            onClick={fetchTranslationRequests}
            size="sm"
            isRound
            isLoading={isRefreshing}
            sx={{
              animation: isRefreshing ? `${spin} 1s linear infinite` : 'none',
            }}
          />
        </Flex>
        <Button colorScheme="teal" onClick={handleNewTranslation}>
          New Translation
        </Button>
      </Flex>

      {errorMessage && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error!</AlertTitle>
            <AlertDescription>{errorMessage}</AlertDescription>
          </Box>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMessage(null)}
          />
        </Alert>
      )}

      <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>File Name</Th>
              <Th>Language</Th>
              <Th>Creation Date</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {translationRequests.map((request) => (
              <Tr key={request.id}>
                <Td><strong>{request.original_file_name}</strong></Td>
                <Td>{request.target_language}</Td>
                <Td>{new Date(request.created_at).toLocaleString()}</Td>
                <Td>{getStatusBadge(request.status, request.progress)}</Td>
                <Td>
                  {request.status === 'completed' && (
                    <Button
                      leftIcon={<DownloadIcon />}
                      colorScheme="teal"
                      size="sm"
                      onClick={() => handleDownload(request.translated_file_url)}
                    >
                      Download
                    </Button>
                  )}
                  {request.status === 'failed' && (
                    <>
                      {loadingReRun.includes(request.id) ? (
                        <Spinner size="sm" />
                      ) : (
                        <Button
                          leftIcon={<RepeatIcon />}
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleReRun(request.id)}
                        >
                          Re-run
                        </Button>
                      )}
                    </>
                  )}
                  {(request.status === 'paid' || request.status === 'parsing_website' || request.status === 'translating') && (
                    <Badge colorScheme="none">
                      <Spinner size="xs" mr={1} /> {`${request.progress}%`}
                    </Badge>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default Index;
