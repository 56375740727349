import React from 'react';
import { Box } from '@chakra-ui/react';

const VideoSection = ({ videoUrl, mt=0 }) => {
  return (
    <Box borderRadius="lg" overflow="hidden" mb={8} mt={mt}>
      <video width="100%" height="315" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default VideoSection;
