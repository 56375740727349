import React, { useEffect } from 'react';
import { Box, Text, Container } from '@chakra-ui/react';
import WeblangMultilingualStepper from '../../components/WeblangMultilingualStepper';
import VideoSection from '../../components/VideoSection';
import NavigationButtons from '../../components/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';

const AddLanguageStep = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkMultilingualActive = async () => {
      try {
        const response = await axiosInstance.get('/wix_multilingual');
        if (!response.data.active) {
          navigate('/weblang_multilingual/install', { state: { showAlert: true } });
        }
      } catch (error) {
        console.error('Error checking multilingual status:', error);
        navigate('/weblang_multilingual/install', { state: { showAlert: true } });
      }
    };

    checkMultilingualActive();
  }, [navigate]);

  const handleNext = () => {
    navigate('/weblang_multilingual/upload-csv');
  };

  const handleBack = () => {
    navigate('/weblang_multilingual/install');
  };

  return (
    <Container maxW="container.md" py={8}>
      <WeblangMultilingualStepper currentStep={1} />
      <VideoSection videoUrl="/weblang_multilingual_2.mp4" />
      <Text mb={8} fontWeight="bold">
        Instructions to Add Target Language and Export Content
      </Text>
      <Text mb={4}>
        1. <strong>Add Language:</strong> In the Wix Multilingual dashboard, click on the <strong>Add Language</strong> button.
      </Text>
      <Text mb={4}>
        2. <strong>Select Target Language:</strong> Choose the language you want to add to your site.
      </Text>
      <Text mb={4}>
        3. <strong>Choose Manual Translation:</strong> Select <strong>Manual Translation</strong> to prevent Wix from charging for automatic translation.
      </Text>
      <Text mb={4}>
        4. <strong>Export Content:</strong> Click on the 3 dots next to the added language and select <strong>Export Content</strong>. You will receive a CSV file with the content to be translated.
      </Text>
      <Text mb={4}>
        5. <strong>Ready to Proceed?</strong> If you have completed these steps, you can proceed to the next step.
      </Text>
      <NavigationButtons onBack={handleBack} onNext={handleNext} />
    </Container>
  );
};

export default AddLanguageStep;
