import React from 'react';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

const steps = [
  { title: 'Set up', description: '' },
  { title: 'Language', description: '' },
  { title: 'Upload CSV', description: '' },
  { title: 'Payment', description: '' },
  { title: 'Translate', description: '' }
];

const WeblangMultilingualStepper = ({ currentStep }) => {
  const { activeStep } = useSteps({
    index: currentStep,
    count: steps.length,
  });

  return (
    <Stepper size='sm' index={activeStep} colorScheme="teal" my={10}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

export default WeblangMultilingualStepper;
