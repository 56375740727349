// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './pages/WeblangMultilingual/Index';
import PricingInfo from './pages/WeblangMultilingual/PricingInfo';
import InstallStep from './pages/WeblangMultilingual/InstallStep';
import AddLanguageStep from './pages/WeblangMultilingual/AddLanguageStep';
import UploadCSVStep from './pages/WeblangMultilingual/UploadCSVStep';
import PaymentStep from './pages/WeblangMultilingual/PaymentStep';
import TranslationStep from './pages/WeblangMultilingual/TranslationStep';
import instanceMiddleware from './middleware/instanceMiddleware';

const App = () => {
  useEffect(() => {
    instanceMiddleware();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/weblang_multilingual" element={<Index />} />
        <Route path="/weblang_multilingual/pricing-info" element={<PricingInfo />} />
        <Route path="/weblang_multilingual/install" element={<InstallStep />} />
        <Route path="/weblang_multilingual/add-language" element={<AddLanguageStep />} />
        <Route path="/weblang_multilingual/upload-csv" element={<UploadCSVStep />} />
        <Route path="/weblang_multilingual/payment/:translationRequestId" element={<PaymentStep />} />
        <Route path="/weblang_multilingual/translate/:translationRequestId" element={<TranslationStep />} />
        {/* Add other routes as needed */}
        <Route path="/" element={<div>Welcome to Rubyroid Tech UI</div>} />
      </Routes>
    </Router>
  );
};

export default App;
