import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/weblang_multilingual/api`, // Replace with your backend URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    const instanceId = sessionStorage.getItem('instanceId');
    if (instanceId) {
      config.headers['X-Instance-Id'] = instanceId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
